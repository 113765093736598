// Node Modules
import PropTypes from "prop-types";

// Components
import ErrorBody from "@/error/ErrorBody";

// Components
import KioskApp from "@/src/KioskApp";

// Scripts
import dataLayerTypes from "@/scripts/constant-types/google-analytics/dataLayerTypes";
import { kioskPageContentEntryIds } from "@/scripts/constant-types/pageContent";

const propTypes = {
  headTitle: PropTypes.string.isRequired,
  pageData: PropTypes.shape({
    data: PropTypes.shape({
      responseCode: PropTypes.number.isRequired,
    }),
  }),
};

const Error = ({
  content,
  headTitle,
  pageData,
}) => {
  const responseCode = pageData?.data?.responseCode;
  const canonicalUrl =
    responseCode && responseCode === 404
      ? "https://www.extraspace.com/404"
      : "https://www.extraspace.com/_error";

  const pageIdentifier = responseCode && responseCode === 404
    ? "404"
    : "error";

  const metaData = {
    canonicalUrl,
    headTitle,
    noIndex: true,
    pageData,
  };

  const {
    errorTitle,
    errorImage,
    errorBodyText,
    errorImageHeight,
    errorImageWidth,
  } = content?.errorFields?.fields;

  const errorPageStyle = {
    paddingTop: "20rem",
  };

  return (
    <KioskApp
      disableBackButton
      content={content}
      metaData={metaData}
      pageIdentifier={pageIdentifier}
    >
      <div style={errorPageStyle}>
        <ErrorBody
          errorBodyText={errorBodyText}
          errorImage={errorImage}
          errorImageHeight={errorImageHeight}
          errorImageWidth={errorImageWidth}
          errorTitle={errorTitle}
        />
      </div>
    </KioskApp>
  );
};

Error.getInitialProps = async ctx => {
  const errorPageType = ctx?.res?.statusCode === 404
    ? "error404"
    : "error500";

  const statusCode = ctx?.res?.statusCode ?? 500;
  const hasGetInitialPropsRun = true;

  const pageData = {
    data: {
      responseCode: statusCode,
    },
    pageType: dataLayerTypes.pageTypes[errorPageType],
    siteType: dataLayerTypes.siteTypes.default,
  };

  return {
    err: ctx.err,
    hasGetInitialPropsRun,
    pageContentEntryId: kioskPageContentEntryIds.errorPage,
    pageData,
  };
};

Error.propTypes = propTypes;

export default Error;
